import React, { Fragment, useContext } from 'react'
import GalleryContent from '../gallery/GalleryContent';
import { GlobalContext } from '../../data/GlobalContext';


const Content = () => {
    const dbData = useContext(GlobalContext);

    return (
        <Fragment>
            {
                dbData.landings.map((items, index) => {
                    return (
                        <div key={index}>
                            <h1 className='py-5 text-center text-capitalize'>
                               {items.name}
                            </h1>
                            <GalleryContent galleryImages={items.gallery} />
                        </div>
                    )
                })
            }
            <div className="py-5"></div>
        </Fragment>
    );
}

export default Content;